<template>
  <div
    class="flex flex-col items-center mb-12 w-9/12 mobile:w-full mx-auto px-16 mobile:px-2"
  >
    <span class="text-5xl font-semibold mb-4 mobile:text-center"
      >Korduma kippuvad küsimused</span
    >
    <div
      class="flex flex-col bg-white w-full rounded-xl shadow mobile:shadow-none"
    >
      <div
        v-for="item in questionArray"
        class="flex flex-col rounded-md"
        :key="item.id"
        :class="item.isExpanded ? 'bg-green text-white' : ''"
      >
        <div class="flex flex-row py-2 px-2">
          <div class="w-11/12 flex px-5 items-center">
            <h2 class="mobile:text-xl">{{ item.title }}</h2>
          </div>
          <div
            class="flex mobile:w-2/12 w-1/12 items-center justify-center text-2xl font-black"
          >
            <button
              @click="expandItem(item)"
              class="hover:bg-white hover:text-black rounded-xl h-12 w-12 p-0 focus:outline-none focus:shadow-outline"
              :class="
                item.isExpanded ? 'bg-white text-black' : 'bg-green text-white'
              "
            >
              <div
                class="duration-100 text-5xl leading-none"
                :class="item.isExpanded ? 'transform rotate-45' : ''"
              >
                {{ returnIcon(item) }}
              </div>
            </button>
          </div>
        </div>
        <div
          class="flex px-5 py-3 font-semibold bg-darkgreen rounded-b-md shadow"
          v-if="item.isExpanded"
        >
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questionArray: [
        {
          id: 0,
          title: "Kellele on Gewodo mõeldud?",
          text:
            "Gewodo on mõeldud eelkõige väikestele ning mikroettevõtetele. Peamiseks suunaks on traditsioonilised teenusepakkujad, ehitusest aianduseni. Kuna meie põhimõte on olla lihtne, arusaadav ja soodne, siis suurematele ettevõtetele me lihtsalt ei keskendu ning meie tooted pole loodud üle 30 töötajaga ettevõtetele. ",
          isExpanded: false
        },
        {
          id: 1,
          title: "Kas ma saan Gewodot enne ostu proovida?",
          text:
            "Jah, sa saad proovida meie Premium versiooni koos iga funktsiooniga 14 päeva jooksul täiesti tasuta. Nii saad kõik asjad üle vaadata ning töö mugavalt sisse seada enne kui paketi ära ostad.",
          isExpanded: false
        },
        {
          id: 2,
          title: "Kuidas alustada prooviperioodi?",
          text:
            "Kui sa liitud Gewodoga, siis sinu prooviperiood algab automaatselt. Sul on 14 päeva, et proovida ära kõik Gewodo Premiumi funktsioonid ning seejärel otsustada, et kas jätkad Baas tasemega või valid Premium paketi. ",
          isExpanded: false
        },
        {
          id: 3,
          title: "Kuidas Gewodo kasutamist alustada?",
          text:
            'See on lihtne. Esiteks loo endale oma ettevõte, mille alt sa tööd haldama hakkad. Seejärel lisa oma töötajad "Töötajate" vaate alt ning saada neile nende profiili alt Gewodoga liitumise kutse. Kui see on tehtud, siis vajuta nupule "Lisa projekt" ja saadki oma esimest projekti Gewodos haldama asuda. Klienti saab lisada nii projekti luues, või ka eraldi ning kliendi Gewodosse kutsumiseks sisesta taas tema email tema profiilil olevasse lahtrisse. ',
          isExpanded: false
        },
        {
          id: 4,
          title: "Kas mu prooviperioodil tehtud tegevused kanduvad üle?",
          text:
            "Jah, kõik mis sa prooviperioodil tegid kandub täielikult üle ka sinu ostetud paketti, ehk võib täiesti rahulikult juba prooviperioodil töö haldamisega alustada.",
          isExpanded: false
        },
        {
          id: 5,
          title: "Kui palju Gewodo projektihaldus maksab?",
          text:
            "Gewodo hinnastamine on lihtne. 0€ eest saad saa baastööriistad, et saaksid oma töö digitaalseks muuta üksikettevõtjana. Gewodo Premium-iga (20€) saad aga oma ettevõtte tegevused ja protsessid kiirelt ja kergelt täielikult digitaliseerida. Täpsemalt hinnastamisest loe siit.",
          isExpanded: false
        },
        {
          id: 6,
          title: "Kuidas toimub maksmine?",
          text:
            "Gewodo süsteem on lihtne. Sa maksad kas oma paketi eest korraga (12 või 3 kuud) või siis igakuiste maksetena. Maksta saad nii kaardi, kui ka pangalingi abil. Kui valid igakuised maksed, siis saad valida oma tellimuste vaatest tulevaste tellimuste alt iga tellimuse perioodi eraldi ning nende eest tasuda kas kaardiga või pangalingiga. Mingit kaardiinfot me ei salvesta ja automaatselt kontolt maha midagi ei võta, ehk maksad siis, kui ise soovid.",
          isExpanded: false
        },
        {
          id: 7,
          title: "Miks on Gewodo nii soodne?",
          text:
            "Gewodo on mõeldud väikestele ettevõtetele. Me teame oma turgu ja seda palju soovitakse maksta, et see ei käiks rahakoti pihta. Me saame seda endale lubada, kuna lõime Gewodo lihtsa ja kõike vajalikku hõlmava paketina just väikeettevõtetele, ehk me ei pea ehitama tööriistu mida kasutavad suurettevõtted ning mida on palju kallim kokku panna ja hallata. ",
          isExpanded: false
        },
        {
          id: 8,
          title:
            "Mis juhtub kui Premium lõppeb aga mul on funktsioonid veel kasutusel?",
          text:
            "Kui sinu Premium pakett lõppeb, siis kaovad sul ligipääsud funktsioonidele, mis on Premiumiga seotud. Näiteks ei pääse ligi su töötajad või kliendid. Sellegipoolest jääb kõik juba loodu (arved, pakkumised, töötajad jne sulle alles. Kohe kui sa aktiveerid uue Premium paketi, siis saad kõike taas kasutada ja samast kohast jätkata. ",
          isExpanded: false
        },
        {
          id: 9,
          title: "Kuidas tellimust uuendada?",
          text:
            'Kui sul on olemasolev tellimus, siis saad osta uue paketi või paketipikenduse "Tellimuste" alt. Seda võid teha igal hetkel ning see lisandub juba sinu olemasolevale paketile. ',
          isExpanded: false
        },
        {
          id: 10,
          title: "Kuidas ma saan tellimuse lõpetada?",
          text:
            "Gewodos on võimalik soetada nii 12 kuu, kui ka 3 kuu pakette. Tellimus lõppeb siis, kui viimase paketi eest on tasutud. Kui soovid paketti pigem lühemaks ajaks, või tahad pidevalt uuendada, siis soovitame võtta 3 kuu pakett. Me ei uuenda automaatselt sinu paketti ja ei võta kaardilt raha maha. See pole meie ärimudel. Ehk kui sinu pakett lõppeb, siis soeta lihtsalt uus sobiva pikkusega pakett ning kõik jätkub samamoodi.",
          isExpanded: false
        },
        {
          id: 11,
          title: "Kas minu dokumendid on hoiustatud turvaliselt?",
          text:
            "Jah, sinu dokumendid on kaitstud välise ligipääsu eest ning sa saad piirata ka ise ligipääsu nendele kasutajapõhiselt. Kõik sinu failid on turvaliselt kindlas pilveserveris, kust nende kadumise või lekkimise tõenäosus on olematu võrreldes kasvõi sinu enda arvutiga.",
          isExpanded: false
        },
        {
          id: 12,
          title: "Mitu töötajat mul võib olla?",
          text:
            "Nii palju kui vaja. Gewodo ei küsi raha iga töötaja eest eraldi. Osta vaid Gewodo pakett ning saad hallata niipalju töötajaid kui soovid. Lihtne.",
          isExpanded: false
        }
      ]
    };
  },
  methods: {
    returnIcon(item) {
      if (item.isExpanded) {
        return "+";
      } else {
        return "+";
      }
    },
    expandItem(q) {
      if (
        this.questionArray.find(item => item.id === q.id).isExpanded !== true
      ) {
        this.questionArray = this.questionArray.map(x => {
          let tempObj = {};
          tempObj = x;

          tempObj.isExpanded = false;
          return tempObj;
        });
        this.questionArray.find(item => item.id === q.id).isExpanded = true;
      } else {
        this.questionArray.find(item => item.id === q.id).isExpanded = false;
      }
    }
  }
};
</script>
